import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Nave" keywords={[`Nave`]} />
    <h1 style={{color: `#FF7216`, margin: `2rem`}}>Nave kan du gjøre hos <a href="//nav.no" target="_blank" rel="noopener noreferrer" style={{color: `#c20700`}}>nav</a>...</h1>
    <div style={{ maxWidth: `100%`, height:`80vh` }}>
    <iframe title="Ut å nave" allowFullScreen frameborder="0" height="100%" src="//www.youtube.com/embed/6xKiz_UBNjQ?rel=0&autoplay=1&loop=1
    &playlist=6xKiz_UBNjQ&controls=0&modestbranding=1" width="100%"></iframe>
    </div>
  </Layout>
)
export default IndexPage
